<script lang="ts" setup>
import type { BlokGrid } from '~~/types/storyblok/bloks'
const props = defineProps<{ blok: BlokGrid }>()

const cards = computed(() => {
  return props.blok?.cards || []
})

const columns = computed(() => {
  const cols = parseInt(props.blok.columns || '0')
  return cols > cards.value?.length ? cards.value.length : cols || 1
})

const gridClass = computed(() => {
  return 'cols-' + columns.value
})

const alignmentClass = computed(() => {
  return props.blok?.alignment === 'center'
    ? 'grid-alignment-center'
    : 'grid-alignment-left'
})
</script>

<template lang="pug">
div(
  v-editable="blok"
  class="container blok-columns flex flex-wrap w-screen md:-mt-48 -mx-18 sm:w-auto sm:mx-auto md:m-auto"
  :class="[gridClass, alignmentClass]"
)
  StoryblokComponent(
    v-for="card in cards"
    :key="card._uid"
    :blok="card"
    class="column basis-full"
  )
</template>

<style lang="postcss" scoped>
.blok-columns {
  &.cols-1 {
    @apply max-w-480;
  }
  &.cols-2 {
    @apply max-w-900;
    .column {
      @apply md:basis-6/12;
    }
  }
  &.cols-3 {
    .column {
      @apply md:basis-4/12;
    }
  }
  &.cols-4 {
    .column {
      @apply md:basis-6/12 xl:basis-3/12;
    }
  }
  &.grid-alignment-center {
    @apply justify-center;
  }
  &.grid-alignment-left {
    @apply justify-start;
  }
}
</style>
